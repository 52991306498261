<template>
  <div>
    <ganttChart :projectId="$route.params.id" />
  </div>
</template>
<script>
import GanttChart from "@/components/Project/gantt/GanttChart";
export default {
  components: {
    ganttChart: GanttChart,
  },
};
</script>
